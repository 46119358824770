import { Controller } from 'stimulus'
import { throttle } from 'lodash-es'

export default class extends Controller {
  initialize () {
    this.handleScroll = this.handleScroll.bind(this)
  }

  connect () {
    this.minValue = this.data.get('min-value') || 130
    this.className = this.data.get('class-name') || 'is-scrolling'
    const throttleDelay = this.data.get('throttle-delay') || 15
    this.handleScroll = throttleDelay > 0 ? throttle(this.handleScroll, throttleDelay) : this.handleScroll
  }

  handleScroll () {
    this.element.classList.toggle(this.className, window.scrollY > this.minValue)
  }
}

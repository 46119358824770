import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["selector", "wrapper"]

  initialize () {
    this.isOpen = false
  }

  connect () {
    this.updateDom()
  }

  toggle (event) {
    event.preventDefault()
    this.isOpen = !this.isOpen

    this.updateDom()
  }

  updateDom () {
    this.wrapperTarget.classList.toggle("active", this.isOpen)

    if (this.hasSelectorTarget) {
      this.selectorTarget.classList.toggle("active", this.isOpen)
    }

    document.documentElement.classList.toggle("overflow-hidden-html", this.isOpen)
    document.body.classList.toggle("overflow-hidden-body", this.isOpen)
  }
}

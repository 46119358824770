import Carousel from "@controllers/carousel_controller"

export default class extends Carousel {
  connect () {
    super.connect()

    this.sectors = document.querySelectorAll('.sector')

    // Set active quarter on the first slide
    this.updateCurrentActiveQuarter(this.swiper.slides[this.swiper.realIndex].id)

    // On change event find item from id into the quarter list and set it active
    this.swiper.on('slideChange', () => { this.updateCurrentActiveQuarter(this.swiper.slides[this.swiper.realIndex].id) })

    // On click event slide to the quarter description slide
    this.sectors.forEach(element => {
      element.addEventListener('click', (e) => { this.slideToThumbQuarter(e) })
    })
  }

  disconnect () {
    super.disconnect()

    this.sectors.forEach(element => {
      element.removeEventListener('click', (e) => { this.slideToThumbQuarter(e) })
    })
  }

  updateCurrentActiveQuarter (id) {
    this.sectors.forEach(element => {
      element.classList.toggle('active', element.id === id)
    })
  }

  slideToThumbQuarter (e) {
    let nextSlideIndex = 0

    for (const [index, element] of this.swiper.slides.entries()) {
      if (element.id === e.target.id) {
        nextSlideIndex = index
      }
    }

    this.swiper.slideTo(nextSlideIndex)
  }

  get defaultOptions () {
    return {
      slidesPerView: 1,
      spaceBetween: 20,
      direction: 'vertical',
      freeMode: false,
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["section", "nav"]

  connect () {
    this.className = this.data.get('class-name') || 'active'
    this.threshold = this.data.get('threshold') || 0.3

    window.addEventListener('load', () => {
      this.initIntersectionObserver()
    }, false)
  }

  initIntersectionObserver () {
    const observerOptions = {
      rootMargin: '0px',
      threshold: this.threshold
    }

    const observer = new IntersectionObserver(this.intersectingHandler.bind(this), observerOptions)
    this.sectionTargets.forEach((section) => {
      observer.observe(section)
    })
  }

  intersectingHandler (entry) {
    entry.forEach((section) => {
      const id = section.target.getAttribute('id')
      this.navTarget.querySelector(`a[href="#${id}"]`).classList.toggle(this.className, section.isIntersecting)
    })
  }
}

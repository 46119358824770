<template lang="pug">
  div
</template>

<script>
export default {
  data () {
    return {
      // message: "Hello Vue from application!"
    }
  }
}
</script>

<style lang="sass" scoped>
p
  font-size: 2em
  text-align: center
</style>

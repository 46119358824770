import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'item']

  connect () {
    const group = this.inputTargets.filter(input => input.checked)[0].dataset.estimateAreasGroup
    this.applyChanges(group)
  }

  toggle (event) {
    const group = event.target.dataset.estimateAreasGroup
    this.applyChanges(group)
  }

  applyChanges (group) {
    this.itemTargets.forEach(item => {
      item.classList.toggle('hidden', item.dataset.estimateAreasGroup === group)
      item.querySelector('input').disabled = item.dataset.estimateAreasGroup === group
    })
  }
}

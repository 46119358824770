import Vue from 'vue'
import { iframeResize } from 'iframe-resizer'

// Plugins
import Transitions from 'vue2-transitions'
import VueMq from 'vue-mq'

// Directive
import vClickOutside from 'v-click-outside'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().forEach(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

// Components
Vue.component('CustomMap', () => import('@components/CustomMap'))
Vue.component('CustomMarker', () => import('@components/CustomMarker'))
Vue.component('ActiveItem', () => import('@components/ActiveItem'))
Vue.component('SelectExample', () => import('@components/SelectExample'))
Vue.component('Search', () => import('@components/Search'))
Vue.component('LocationsSelect', () => import('@components/LocationsSelect'))

Vue.use(Transitions)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: Infinity
  }
})
Vue.use(vClickOutside)

Vue.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  }
})

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app',
    mounted () {
      const vueMountedEvent = new CustomEvent('vueMounted')
      document.dispatchEvent(vueMountedEvent)
    }
  })
})

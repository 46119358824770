import { Controller } from 'stimulus'
import { gsap, TweenLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class extends Controller {
  static targets = ["content", "link"]

  connect () {
    this.isOpen = false

    this.maxHeight = this.data.get('max-height') || '120'
    this.textOpen = this.data.get('text-open') || 'En savoir plus'
    this.textClose = this.data.get('text-close') || 'Réduire'

    this.initializeTweenLite()
  }

  initializeTweenLite () {
    if (this.contentTarget.offsetHeight > this.maxHeight) {
      TweenLite.to(this.contentTarget, 0.2, { height: this.maxHeight + 'px', overflow: 'hidden' })
      TweenLite.set(this.linkTarget, { opacity: '1', pointerEvents: 'auto' })
      this.linkTarget.innerHTML = this.textOpen
    } else {
      TweenLite.set(this.linkTarget, { display: 'none' })
    }
  }

  handleClickLink () {
    !this.isOpen ? this.openAnimation() : this.closeAnimation()
    this.element.classList.toggle("active", !this.isOpen)
    this.isOpen = !this.isOpen
  }

  closeAnimation () {
    TweenLite.to(this.contentTarget, 0.5, { height: this.maxHeight })
    this.linkTarget.innerHTML = this.textOpen
  }

  openAnimation () {
    TweenLite.set(this.contentTarget, { height: 'auto' })
    TweenLite.from(this.contentTarget, 0.5, { height: this.maxHeight })
    this.linkTarget.innerHTML = this.textClose
  }
}

import Remote from "stimulus-remote-rails"
import Vue from 'vue'
import { debounce } from 'lodash'

export default class extends Remote {
  static targets = ['desktopInput', 'mobileInput']

  connect () {
    this.toggleInputs()

    this.debouncedToggleInput = debounce(this.toggleInputs.bind(this), 300)
    window.addEventListener("resize", this.debouncedToggleInput)
  }

  disconnect () {
    window.removeEventListener('resize', this.debouncedToggleInput)
  }

  replace (event) {
    super.replace(event)
    const [,, xhr] = event.detail

    history.pushState(null, null, xhr.responseURL)

    new Vue({
      el: '#vue-app'
    })
  }

  toggleInputs () {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      this.desktopInputTargets.forEach(i => { i.disabled = false })
      this.mobileInputTargets.forEach(i => { i.disabled = true })
    } else {
      this.desktopInputTargets.forEach(i => { i.disabled = true })
      this.mobileInputTargets.forEach(i => { i.disabled = false })
    }
  }
}

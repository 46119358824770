import VanillaModal from 'vanilla-modal'

export default class {
  constructor () {
    new VanillaModal({
      modal: '.modal-template',
      modalInner: '.modal-template__inner',
      modalContent: '.modal-template__content'
    })
  }
}

import AutocompleteController from '@controllers/autocomplete_controller'

export default class extends AutocompleteController {
  static targets = [
    'cityId',
    'sectorId',
    'listableId',
    'listableType'
  ]

  selectCity (event) {
    event.preventDefault()

    const value = event.currentTarget.dataset.value

    if (this.hasListableTypeTarget) {
      this.listableTypeTarget.value = 'City'
      this.listableIdTarget.value = value
    } else {
      this.sectorIdTarget.value = ''
      this.cityIdTarget.value = value
    }

    this.inputTarget.value = event.currentTarget.textContent
    this.closeResults()
  }

  selectSector (event) {
    event.preventDefault()

    const value = event.currentTarget.dataset.value

    if (this.hasListableTypeTarget) {
      this.listableTypeTarget.value = 'Sector'
      this.listableIdTarget.value = value
    } else {
      this.cityIdTarget.value = ''
      this.sectorIdTarget.value = value
    }

    this.inputTarget.value = event.currentTarget.textContent
    this.closeResults()
  }
}

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import ScrollTo from "stimulus-scroll-to"
import Remote from "stimulus-remote-rails"
import CharacterCounter from "stimulus-character-counter"
import Reveal from "stimulus-reveal-controller"
import PlacesAutocomplete from "stimulus-places-autocomplete"

const application = Application.start()
const context = require.context('@controllers', true, /\.js$/)

application.load(definitionsFromContext(context))
application.register("remote", Remote)
application.register("scroll-to", ScrollTo)
application.register("character-counter", CharacterCounter)
application.register("reveal", Reveal)
application.register("places", PlacesAutocomplete)

/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "@appPages/vue"

import "stylesheets/application.sass"
import "stylesheets/tailwind.sass"

import 'intersection-observer'
import AnimationScroll from "@utils/animation-scroll"
import ImageObjectFit from "@utils/image-object-fit"
import Modale from "@utils/modale"
import ConstantHeight from "@utils/constant-height"
import FlashMessage from "@utils/flash-messages"
import SmoothScroll from 'smoothscroll-polyfill'

// Fonts ===============================================================
import "typeface-source-sans-pro"

// Stimulus
import "@controllers/index.js"

// CONFIGURATION ===============================================================
Rails.start()
SmoothScroll.polyfill()

window.FlashMessage = FlashMessage

window.recaptchaCallback = (recaptchaElement) => {
  const form = recaptchaElement.closest('form')

  if (form.getAttribute('data-remote') === 'true') {
    Rails.fire(form, 'submit')
  } else {
    form.submit()
  }
}

window.onloadCallback = () => {
  document.querySelectorAll('.g-recaptcha').forEach(recaptchaElement => {
    const widgetId = recaptchaElement.dataset.recaptchaWidgetId
    if (widgetId) {
      grecaptcha.reset(widgetId)
    } else {
      const attributes = {
        sitekey: '6LcTyhEaAAAAAAz4q5eXnRtRQ72dMvp1HZgpyrAa',
        size: 'invisible',
        callback: () => {
          recaptchaCallback(recaptchaElement)
        }
      }
      recaptchaElement.dataset.recaptchaWidgetId = grecaptcha.render(recaptchaElement, attributes)
    }
  })
}

// execute challenge for Recaptcha that are not set on submit buttons (which are automatically executed upon form's submission)
window.executeRecaptcha = (event) => {
  event.preventDefault()

  const form = event.target.closest('form')
  if (!form.reportValidity()) return

  grecaptcha.execute(form.querySelector('.g-recaptcha').dataset.recaptchaWidgetId)
}

window.googlePlaceAutocompleteReady = function () {
  const event = new Event('GooglePlaceAutocompleteReady', { bubbles: true, cancelable: true })
  window.dispatchEvent(event)
}

document.addEventListener("DOMContentLoaded", () => {
  new AnimationScroll()
  new ImageObjectFit()
  new Modale()
  new ConstantHeight()

  document.addEventListener('ajax:error', () => {
    onloadCallback()
  })
})

// FIX : wrong width init slider
window.addEventListener('load', () => {
  window.dispatchEvent(new Event("resize"))
}, false)

import Lightbox from "stimulus-lightbox"

export default class extends Lightbox {
  connect () {
    // We need to remove the old gallery.
    // Because Vue removes all the events on the page on page load.
    this.element.removeAttribute('lg-uid')

    super.connect()
  }

  get defaultOptions () {
    return {
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: '.item'
    }
  }
}
